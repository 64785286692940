import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'

export const recordsQuery = graphql`
  query recordsQuery($skip: Int!, $limit: Int!) {
    allContentfulRecord(limit: $limit, skip: $skip) {
      nodes {
        title
        slug
        description {
          description
        }
        publishDate(formatString: "DD.MM.YYYY")
      }
    }
  }
`

const RecordsTemplate = ({ data, pageContext }) => {
  const records = data.allContentfulRecord.nodes
  const { currentPage, numRecordsPages } = pageContext

  return (
    <Layout>
      <div className='container main'>
        <div className='row'>
          <div className='sixteen columns post-overview'>
            {records?.map((record, index) => (
              <article className='post' key={index}>
                <div className='post-header'>
                  <div className='post-title'>
                    <h2 className='title-text'>
                      <a href={`/record/${record.slug}`}>{record.title}</a>
                    </h2>
                    <ul className='post-meta'>
                      <li>
                        <i className='icon-history'></i> {record.publishDate}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='post-body'>
                  <p>{record.description?.description}</p>
                  <a className='post-read' href={`/record/${record.slug}`}>
                    Čtěte dále →
                  </a>
                </div>
              </article>
            ))}

            <div className='pagination'>
              <ul>
                <li>
                  <Link to='/records' rel='prev' className='first'>
                    « První
                  </Link>
                </li>
                {Array.from({ length: numRecordsPages }, (_, i) => (
                  <li key={`pagination-number${i + 1}`} className={i + 1 === currentPage ? 'active' : ''}>
                    <Link to={`/records/${i === 0 ? '' : i + 1}`}>{i + 1}</Link>
                  </li>
                ))}
                <li>
                  <Link to={`/records/${numRecordsPages > 1 ? numRecordsPages : ''}`} rel='next' className='last'>
                    Poslední »
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RecordsTemplate
